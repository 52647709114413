import { useEffect, useState } from "react";
import styled from "styled-components";

const Cont = styled.div`
  padding: 15px;
  padding-right: 40px;
`
const ContImg = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img{
    margin: 0 10px 10px 0;
    width: 75px;
    height: auto;
  }
`
const ContText = styled.div`
  display: flex;
  flex-direction: column;
  p{
    
    
    overflow: hidden;
    font-size: 13px;
    line-height: 18px;
    color: #303030;
    display: ${(props) => props.isMore ? "block" : "-webkit-box;"};
    -webkit-line-clamp: ${(props) => props.isMore ? "unset" : 2} ;
    -webkit-box-orient: ${(props) => props.isMore ? "unset" : "vertical"} ;
    text-overflow: ${(props) => props.isMore ? "unset" : "ellipsis"} ; ;
  }
`

const BtnText = styled.span`
  margin: 8px 0 0 0;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
  color: #9F9F9F;
`

const BottomWrap = styled.div`
  padding-top: 15px;
  .name{
    font-size: 13px;
    color: #9F9F9F;
  }
  .text{
    margin-top: 4px;
    line-height: 19px;
    font-size: 12px;
    color: #707070;
  }
`


/**
 * 상품 리뷰 컨텐츠 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductReviewContent = ({review}) => {
  const [isMore,setIsMore] = useState(false);
  return (
    
    <Cont>
      <ContImg>
        {
          review.origin_attributes != null && review.origin_attributes.product_options !== undefined ? 
          review.origin_attributes.images.map((item,index)=>(
            <img key={index} src={item.thumbnail_url} loading="lazy"/>
          ))
          : null
          
        }
      </ContImg>
      <ContText isMore={isMore}>
        <p>
          {review.contents}
        </p>
        <BtnText onClick={() => setIsMore(!isMore)}>
          {isMore ? "숨기기" : "더보기"}
        </BtnText>
      </ContText>
      <BottomWrap>
        <div className="name">**</div>
        <div className="text">
          <span>{review.likes_count}</span>명 중 <span>{review.plus_likes_count}</span>명이 이 리뷰가 도움 된다고 선택했습니다.
        </div>
      </BottomWrap>
    </Cont>
  )
}

export default ProductReviewContent;