import { useEffect, useState } from "react"
import styled from "styled-components"
import Icons from "../Icons"
const ProductWrap = styled.div`
  height:  ${props => props.isMore ? "auto" : "500px"};
  overflow: hidden;
`

const MoreWrap = styled.div`
  .btnMore{
    position: relative;
    border: 1px solid #efefef;
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .btnMore::before{
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    margin-bottom: 1px;
    width: 100%;
    height: 105px;
    background: ${(props) => props.isMore ? " ": "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)"};
  }
  p{
    font-size: 15px;
    font-weight: 500;
    color: #1f1f1f;
  }
`
const MoreIcon = styled.span`
  width: 22px;
  height: 22px;
  margin-left: 3px;
`



/**
 * 상품 상세 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductDetail = ({description,size}) => {
  
  const [more,setMore] = useState(false);
  const detailMore = () =>{
    setMore(!more);
  }

  /**
   * 상세 내용안에 있는 스크립트가 실행되어야 되기에 스크립트를 가져온후 돔에 추가합니다
   */
  useEffect(() => {
    const re = /<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g
    const results = description.match(re)
    
    if(results !== null){
      const script = document.createElement("script");
      const div = document.createElement("div")
      for(let item of results){
        item = item.split("<script>").join(""); 
        item = item.split("</script>").join(""); 
        script.innerHTML += item;
      }
      div.appendChild(script);
      document.body.appendChild(div);
    }
  },[])  

  return (
    <div id="product_detail_wrap">
      <div className="detail_wrap">
        <div id="productDtail">
          <ProductWrap isMore={more}>
            <div id="prdDetail" className="detail">
              <div className="detail_cont_wrap">
                <div className="detail_cont">
                  <div dangerouslySetInnerHTML={ {__html: description} }>
                  </div>
                  <div dangerouslySetInnerHTML={ {__html: size} }>
                  </div>
                </div>
              </div>
            </div>
          </ProductWrap>
          
          <MoreWrap isMore={more}>
            <div className="btnMore" onClick={detailMore}>
              {!more ? 
              <>
                <p>상품정보 더보기</p>
                <MoreIcon>
                  <Icons url={'https://slim9-push.s3.ap-northeast-2.amazonaws.com/icon_more_down.png'}/>
                </MoreIcon>
              </> : 
              <>
                <p>상품정보 접기</p>
                <MoreIcon>
                  <Icons url={'https://slim9-push.s3.ap-northeast-2.amazonaws.com/icon_more_up.png'}/>
                </MoreIcon>
              </>}
              
            </div>
          </MoreWrap>
        </div>
      </div>
    </div>

  )
}

export default ProductDetail;