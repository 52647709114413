import styled from "styled-components"

const ReviewCount = styled.div`
  padding: 28px 0 20px;
  display: flex;
  align-items: center;
`

const ReviewGrade = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center; 
  border-left: ${(props)=>props.isBorder == 1 ? "1px solid #efefef;" : "0px"};
  span{
    display: flex;
    font-size: 13px;
    font-weight: 500;
    color: #707070;
    margin: 0 0 10px 0;
  }
  strong{
    line-height: 38px;
    font-size: 28px;
    font-weight: bold;
    color: #1d1d1d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    width: 38px;
    height: 38px;
    margin: -3px 8px 0 0;
  }
`

const Write = styled.div`
  margin: 0 0 30px 0;
  a{
    display: flex;
    height: 42px;
    font-size: 14px;
    font-weight: bold;
    color: #CD3D65;
    border: 1px solid #CD3D65;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    min-width: 113px;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
  }
`


/**
 * 상품 리뷰 통계 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductReviewCount = ({statistics}) =>{
  const onReview = () => {
    window.location.href = "https://slim9.biz/my-account/order-reviews";
  }
  return (
    <>
      <ReviewCount>
        <ReviewGrade isBorder={0}>
          <span>구매자 평점</span>
          <strong >
            <img src="https://slim9.biz/assets/images/icon_star.png" loading="lazy" width={38} height={38}/> 
            <strong >{statistics.avg}</strong>
          </strong>
        </ReviewGrade>
        <ReviewGrade isBorder={1}>
          <span>구매자 평점</span>
          <strong >{statistics.count}</strong>
        </ReviewGrade>
      </ReviewCount>
      <Write>
        <a onClick={onReview}>리뷰 작성</a>
      </Write>
    </>
  )
}

export default ProductReviewCount;