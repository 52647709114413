import styled from "styled-components"
import logo from "../../../assets/image/logo_icon.png";
const Wrapper = styled.div`
  background: rgba(0,0,0,.5);
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
`

const Pop = styled.div`
  background: #fff;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  padding: 20px 15px;
`

const PopTitle = styled.h1`
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
`

const PopBtn = styled.div`
  margin-top: 20px;
`

const Btn = styled.button`
  display: inline-block;
  width: 45%;
  align-items: center;
  justify-content: center;
  min-width: 113px;
  padding: 0 10px;
  height: 40px;
  font-size: 13px;
  color: #ffffff;
  border: ${(props) => props.btnType == "cart" ? "1px solid #CD3D65;" : "1px solid #9F9F9F;"};
  background-color: ${(props) => props.btnType == "cart" ? "#CD3D65;" : "#9F9F9F;"};;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  margin-right: ${(props) => props.btnType == "cart" ? "10px" : "0px"};
`



const Popup = styled.div`
  padding: 14px 12px;
  border-radius: 4px;
  background-color: rgba(0,0,0,0.9);
  position: fixed;
  bottom: 75px;
  left: 15px;
  right: 15px;
  z-index: 999;
  -webkit-animation: fadein 0.3s, fadeout 0.3s 2s;
  animation: fadein 0.3s, fadeout 0.3s 2s;
  @keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
  }

`
const PopupInner = styled.div`
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    display: flex;

    p {
      height: 18px;
      line-height: 18px;
      font-size: 14px;
      color: #F0EEE9;
    }

    .btn-go-to-card {
      height: 35px;
      margin: -7.5px 0;
      padding: {
        top: 7.5px;
        bottom: 7.5px;
        right: 20px;
        left: 5px;
      }
      font-size: 14px;
      color: #5290FF;
      position: relative;
      background: transparent;
      border: none;

      &:after {
        content: '';
        width: 7px;
        height: 7px;
        border: {
          width: 1px 0 0 1px;
          style: solid;
          color: #5290FF;
        }
        position: absolute;
        transform: translateY(-50%) rotate(135deg) skew(-5deg, -5deg);
        top: calc(50% + 1px);
        right: 5px;
        display: block;
      }
    }
`




/**
 * 장바구니 이동 모달
 * @param {*} param0 
 * @returns 
 */
const CartModal = ({hideModal}) => {
  const goCart = () => {
    hideModal();
    window.location.href = "https://slim9.biz/cart";
  }

  const continew = () => {
    hideModal();
  }

  return (
    // <Wrapper>
    //   <Pop>
    //     <img src={logo}/>
    //     <PopTitle>
    //       장바구니에 상품이<br/>정상적으로 담겼습니다.
    //     </PopTitle>
    //     <PopBtn>
    //       <Btn btnType='cart' onClick={goCart}>장바구니 이동</Btn>
    //       <Btn btnType='continew' onClick={continew}>쇼핑 계속하기</Btn>
    //     </PopBtn>
    //   </Pop>
    // </Wrapper>
    <>
      <Popup>
        <PopupInner>
          <p>장바구니에 상품을 담았습니다.</p>
          <button className="btn-go-to-card" onClick={goCart}>장바구니로 가기</button>
        </PopupInner>
      </Popup>
    </>
  )
}

export default CartModal;