import styled from "styled-components";
import Icons from "../Icons";

const Shipping = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 70px;
  background: #ffffff;
`

const Tit = styled.div`
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #1f1f1f;
`

const Act = styled.span`
  display: block;
  width: 24px;
  height: 24px;
`

/**
 * 배송 교환 상품 안내 컴포넌트
 * @returns 
 */
const ProductRefund = () => {

  const goRefund = () =>{
    window.location.href = "https://slim9.biz/products/claims"
  }
  return(
    <Shipping onClick={goRefund}>
      <Tit>배송/교환/반품 안내</Tit>
      <div>
        <Act>
          <Icons url={'https://slim9.biz/assets/images/icon_link.png'}/>
        </Act>
      </div>
    </Shipping>
  )
}

export default ProductRefund;