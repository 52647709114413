
import styled from "styled-components";
import { changePriceFormat, checkDevice } from "../../util";
import Icons from "../Icons";
import {useSelector} from "react-redux"
import { useQuery } from "react-query";
import { getCoupos } from "../../server/api";
import CouponModal from "./coupon/coupon_modal";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useCallback } from "react";
const Info = styled.div`
  position: relative;
  padding: 13px 20px 30px;
  border-bottom: 1px solid #efefef;
`

const InfoName = styled.div`
  margin: 0 0 20px 0;
  padding: 0 30px 0 0;
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
`

const Price = styled.div`
  display: flex;
  align-items: center;
`

const Current = styled.p`
  margin: 0 20px 0 0;
  width: 68px;
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  .couponPrice{
    line-height: 22px;
    font-size: 16px;
    font-weight: bold;
    color: #CD3D65;
  }
`

const Discount = styled.p`
  font-size: 14px;
  color: #CFCFCF;
  text-decoration: line-through;
  span{
    color: #CFCFCF;
  }
`

const Share = styled.div`
  right: ${(props)=>props.isDevice == "android" ? "" : "25px"};
  position: absolute;
  top: 13px;
  right: 20px;
  width: 25px;
  height: 25px;
`

const BtnShare = styled.button`
  display: block;
  width: ${(props)=>props.isDevice == "android" ? "30px" : "30px"};
  height: 30px;
  background: white;
  border: 0px;
`


const Coupon = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
`

const CouponText = styled.p`
  flex-shrink: 0;
  padding: 0 10px;
  height: 22px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  background: #CD3D65;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CouponBtn = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  height: 42px;
  line-height: 20px;
  font-size: 14px;
  color: #CD3D65;
  border: 1px solid #CD3D65;
  background: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const BtnDownload = styled.span`
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #CD3D65;
  letter-spacing: -0.5px;
  span{
    color: #CD3D65;
  }
`
const DownIcon = styled.span`
  width: 20px;
  height: 20px;
`

/**
 * 상품명,상품가격, 공유등 상품 정보 관련 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductInfo = ({name,price,regularPrice}) => {
  const productIdx = useSelector((state) => state.productState.idx);
  const {data:coupons,isLoading} = useQuery(["coupons"],() => getCoupos(productIdx));
  const [maximumDiscout,setMaximumDiscout] = useState("");
  const [maximunCoupon,setMaxiumnCoupon] = useState("");
  const [isCouponModal,setIsCouponModal] = useState(false);
  const share = () => {
    try {
      window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
        action: 'share_up',
        sharetxt: `http://app.slim9.co.kr/products/${productIdx}`,
      }))
    } catch (e) {
    }
  }

  const showModal = () => {
    setIsCouponModal(true);
  }

  const onModalHide = useCallback(()=>{
    setIsCouponModal(false);
  })

  useEffect(()=>{
    if(!isLoading){
      for(let item of coupons){
        item.applied_price = price * item.rate
        const detal = (item.rate_type ? price * (item.amount * 0.01) : item.amount)
        const max = Math.max(detal,maximumDiscout);
        setMaximumDiscout(max);
      }
    }
  },[isLoading])

  useEffect(()=>{
    if(maximumDiscout > 0){
      if(!isLoading){
        let curCoupon = _.find(coupons,coupon => {
          let discoundAmount = price - coupon.applied_price
          return maximumDiscout === discoundAmount;
        })
        setMaxiumnCoupon(curCoupon)
      }
    }
  },[maximumDiscout])
  return (
    <Info>
      <InfoName>{name}</InfoName>
      <div>
        <Price>
          <Current>
            <span>{changePriceFormat(price)}</span>원
          </Current>
          <Discount>
            <span>{changePriceFormat(regularPrice)}</span>원
          </Discount>
        </Price>


        <Share isDevice={checkDevice()}>
          <BtnShare onClick={share} isDevice={checkDevice()}>
            <Icons url={'https://slim9.biz/assets/images/icon_share2.png'}/>
          </BtnShare>
        </Share>
      </div>
    </Info>
  );
}

export default ProductInfo;
