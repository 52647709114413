import styled from "styled-components";
import {useQuery} from "react-query"
import { getProductQna } from "../../../server/api";
import { useState } from "react";
import {useSelector} from "react-redux"
import ProductQnaItem from "./product_qna_item";
import QnaSkeleton from "./qna_skeleton";


const QnaWrap = styled.div`
  padding: 20px;
`

const QnaTitle = styled.div`
  margin: 16px 0 30px 0;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  font-weight: bold;
  color: #1f1f1f;
`

const Question = styled.div`
  padding: 0 0 40px 0;
`
const Buttons = styled.div`
  display: flex;
  margin: 0 0 40px 0;
`
const QnaBtn = styled.div`
  flex: 1;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 113px;
  padding: 0 10px;
  font-size: 13px;
  
  text-align: center;
  white-space: nowrap;
  margin-left: ${props => props.btnType === 'all' ? "10px" : "0px"};

  color:  ${props => props.btnType === 'all' ? "#303030" : "#ffffff;"};
  border: ${props => props.btnType === 'all' ? "1px solid #D3D3D3;" : "1px solid #CD3D65;"};
  background: ${props => props.btnType === 'all' ? "#ffffff;" : "#CD3D65;"};;
`

const Qna = styled.ul``

const EmptyQna = styled.div`
  width: 100%;
  text-align: center;
  img{
    width: 50px;
  }
  p{
    margin-top: 10px;
    color: #918e8e;
  }
`

/**
 * 상품 qna 컴포넌트
 * @returns 
 */
const ProductQna = () => {  
  const productIdx = useSelector((state) => state.productState.idx);
  const [qna,setQna] = useState([]);
  const {isLoading} = useQuery(["productQna"],() => getProductQna(productIdx),{ onSuccess: setQna })

  console.log(productIdx);

  const writeQna = () => {
    window.location.href = "https://slim9.biz/products/"+productIdx+"/inquiry";
  }

  const allQna = () => {
    window.location.href = "https://slim9.biz/inquiries";
  }

  return (
    <div id="productQna">
      <QnaWrap>
        <QnaTitle>상품 Q&A</QnaTitle>
        <Question>
          <Buttons>
            <QnaBtn btnType={'qna'} onClick={writeQna}>상품 문의하기</QnaBtn>
            <QnaBtn btnType={'all'} onClick={allQna}>모두 보기</QnaBtn>
          </Buttons>
          {isLoading ? 
            [1,2.3].map((item,index)=>{
              <QnaSkeleton key={index}/>
            })
            : 
            qna.length ? 
            <Qna>
              {qna.map((item,index) => (
                <ProductQnaItem key={index} item={item}/>
              ))}
            </Qna> : 
            <EmptyQna>
              <img src="https://slim9.biz/assets/images/icon-empty.png" loading="lazy"/>
              <p>등록된 Q&A 가 없습니다 T.T</p>
            </EmptyQna>
          }
        </Question>
      </QnaWrap>
    </div>

  )
}
export default ProductQna;