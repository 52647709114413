import styled from "styled-components";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkaletonItem = styled.li`
  position: relative;
`

const SkaletonOpen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0;
  min-height: 50px;
  background: transparent;
  border: none;
  background: #f8f5f5;
  margin-top: 5px;
`

const SkaletonTitle = styled(Skeleton)`
  height: 20px;
  width: 200px;
  margin-left: 10px;
`

const SkaletonTxt = styled(Skeleton)`
  width: 220px;
  height: 25px;
  margin-left: 10px;
  margin-top: 10px;
`

/**
 * 상품 qna 스켈레톤 UI
 * @returns 
 */
const QnaSkeleton = () =>{
  return (
    <SkaletonItem>
      <SkaletonOpen>
        <SkaletonTitle/>
        <SkaletonTxt/>
      </SkaletonOpen>
    </SkaletonItem>
  )
}

export default QnaSkeleton;