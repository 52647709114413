import { useEffect, useState } from "react"
import styled from "styled-components"
import BuyModal from "./buy_modal"
import Icons from "../../Icons"
import {useSelector} from "react-redux"
import { useQuery } from "react-query"
import { setCartItem, setToggleFavorite, getCartCount } from "../../../server/api"
import CartModal from "./cart_modal"
import { checkDevice, logEvent, recordEvent } from "../../../util"
const BottomWrap = styled.div`
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  background: #fff;
  padding: 8px 12px;
`



const BuyBtn = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 52px;
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  margin-right: ${props => props.btnType === 'buy' ? '0px' : '8px'};
  border: ${props => props.btnType === 'buy' ? '1px solid #CD3D65' : '1px solid #CD3D65'};
  background-color: ${props => props.btnType === 'buy' ? '#CD3D65' : '#fff'};
  color: ${props => props.btnType === 'buy' ? '#fff' : '#CD3D65'};
  z-index: 801;
`

const SoldOut = styled(BuyBtn)`
  background-color: #333 !important;
  border: 0 !important;
  color: #fff;
`

const WishList = styled.button`
  width: ${(props)=>props.isDevice === "android" ? "40px" : "50px"};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 52px;
  flex-shrink: 0;
  width: 40px;
  border-radius: 14px;
  margin-right: 8px;
  background: white;
  border: none;
  z-index: 801;
  margin-left: 15px;
  margin-right: 15px;
  p{
    font-size: 12px;
    color: #9F9F9F;
  }
`


/**
 * 페이지 하단 찜,장바구니,구매하기버튼 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const BuyBottom = ({options,productItem,product,cartCount}) => {
  
  const userIdx = useSelector((state) => state.userState.idx)
  const [isShow,setIsShow] = useState(false);
  const [isModal,setIsModel] = useState(false);
  
  const [isFavorite,setIsFavorite] = useState(false);
  const [favorite,setFavorite] = useState(0);
  const userProducts = useSelector((state) => state.userState.list);

  const {data : count,isLoading} = useQuery(["cartCount"],() => getCartCount(userIdx));

  const productBuy = async () => {
    if(userIdx !== 0){
      if(isShow){
        if(userProducts.length == 0){
          alert("상품 옵션을 선택해주세요.");
          return;
        }else{

          const {success} = await setCartItem(userProducts,userIdx);
          if(success){

            let af_price = [];
            let af_content = "";
            let af_content_id = [];
            let af_content_type = [];
            let af_currency = [];
            let af_quantity = [];
  
            for(let item of userProducts){
              af_price.push(item.unitPrice)
              af_content_id.push(item.productItemId)
              af_content_type.push(item.categories)
              af_currency.push("KRW");
              af_quantity.push(item.quantity)
            }
  
            let eventName = "af_add_to_cart"
            let eventParams = `{\"af_price\":\"'${af_price.join()}'\",\"af_content\":\"'${af_content}'\",\"af_content_id\":\"'${af_content_id.join()}'\",\"af_content_type\":\"'${af_content_type.join()}'\",\"af_currency\":\"'${af_currency.join()}'\",\"af_quantity\":\"'${af_quantity.join()}'\"}`
            recordEvent(eventName,eventParams)
  
            let gaName = "add_to_cart"
            let gaPrams = userProducts;
            logEvent(gaName,gaPrams);

            console.log(success);
            console.log(success);
            cartCount();
            setIsModel(true);
            setIsShow(false);
            setTimeout(()=>{
              setIsModel(false);
            },2300);
          }
        }
        
      }else{
        setIsShow(true);
      }
    }else{
      alert("로그인 이후 구매가 가능합니다.");
      return;
    }
  }

  const hideModal = () => {
    setIsModel(false);
  }

  const toggleFavorite = async () => {
    if(userIdx !== 0){
      const {success,favorite} = await setToggleFavorite(userIdx,productItem.id,isFavorite)
      if(success){
        setFavorite(favorite);
        setIsFavorite(!isFavorite);
      }
    }else{
      alert("로그인 이후 구매가 가능합니다.");
      return;
    }
    
  }

  const soldClick = () => {
    alert("품절된 상품 입니다.");
    return;
  }

  useEffect(()=>{
    productItem.userFavorite == null ? setIsFavorite(false) : setIsFavorite(true);
    setFavorite(productItem.favorite)
  },[])


  return (
    <>
      <BottomWrap>
        <WishList isDevice={checkDevice()} className="buys" onClick={toggleFavorite}>
          {
            !isFavorite ?
            <Icons className="buys" url={'https://slim9.biz/assets/images/icon_heart_off.png'}/>
            : 
            <Icons className="buys" url={'https://slim9.biz/assets/images/icon_heart_off_fill.png'}/>
          }
          <p className="buys">{favorite}</p>
        </WishList>
        {
        isShow ? 
        <BuyBtn className="buys" btnType={'cart'} onClick={productBuy}>장바구니</BuyBtn>
        : null
        }
        {
          product.item.sold_out ? 
          (<SoldOut  className="buys" btnType={'buy'} onClick={soldClick}>품절</SoldOut>)
          :
          <BuyBtn  className="buys" btnType={'buy'} onClick={productBuy}>구매하기</BuyBtn>
        }
        
        
        
        {
        isShow ? 
        <BuyModal options={options} productItem={productItem} hideModal={() => setIsShow(false)}/>
        : null
        }
      </BottomWrap>
      {isModal ? <CartModal hideModal={hideModal}/> : null}
    </>
   
  )
}

export default BuyBottom;