import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { checkDevice } from "../util"
import {useSelector} from "react-redux"
import { useQuery } from "react-query"
import { getCartCount } from "../server/api"

const TitleWrap = styled.div`
  position: sticky;
  z-index: 20;
  top: 0;
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #efefef;
  background-color: #ffffff;
`

const Title = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #1f1f1f;
`
const Util = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`

const UtileBtn = styled.button`
  width: ${(props) => props.isDevice === "android" ? "35px" : "35px"} ;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden !important;
  border: 0px;
  background: white;
`

const Icon = styled.i`
  display: block;
  width: 100%;
  height: 100%;
  background: url(${props => props.background}) no-repeat 50% 50% / 100% auto;;
`


const Shop = styled.div`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
`

const CartCount = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #EB5049;
  line-height: 14px;
  text-align: center;
  font-weight: 700;
  font-size: 10px;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`

/**
 * 페이지 최상단 UI
 * @returns 
 */
const Header = ({cartCount}) => {
  const userIdx = useSelector((state) => state.userState.idx);
  const {data : count} = useQuery(["cartCount"],() => getCartCount(userIdx));

  const search = () => {
    window.location.href = "https://slim9.biz/products"
  }
  const cart = () => {
    window.location.href = "https://slim9.biz/cart"
  }
  const main = () => {
    window.location.href = "https://slim9.biz/"
  }
  const back = () =>{
    window.history.back();
  }
 
  useEffect(()=>{
     
  },[])
  return (
    <>
      <TitleWrap>
        <Title>상품정보</Title>
        <Util>
          <UtileBtn onClick={back} isDevice={checkDevice()}>
            <Icon background={'https://slim9.biz/assets/images/icon_back.png'}/>
          </UtileBtn>
          <UtileBtn onClick={main} isDevice={checkDevice()}>
            <Icon background={'https://slim9.biz/assets/images/appbar-icons/home.svg'}/>
          </UtileBtn>
        </Util>
        <Shop>
          <UtileBtn onClick={search} isDevice={checkDevice()}>
            <Icon background={'https://slim9.biz/assets/images/icon_zoom.png'}/>
          </UtileBtn>
          <UtileBtn onClick={cart} isDevice={checkDevice()}>
            {
              <CartCount>
                {cartCount ? cartCount : count}
              </CartCount>
            }
            <Icon background={'https://slim9.biz/assets/images/icon_cart.png'}/>
          </UtileBtn>
        </Shop>
      </TitleWrap>
    </>
  );
}

export default Header;