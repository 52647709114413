import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {QueryClient,QueryClientProvider} from "react-query"
import { createGlobalStyle } from 'styled-components';

const queryClient = new QueryClient({
	defaultOptions : {
		queries : {
				refetchOnWindowFocus : false,
					refetchOnMount : false,
					retry : false
			}
	}
});

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap');
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video,select {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	color: #000;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	overflow-x: hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*{
  box-sizing: border-box;
}
body{
  font-family: 'Source Sans Pro', sans-serif;
}
a{
  text-decoration: none;
  color:inherit;
}

.swiper-pagination-bullet{
	margin: 0 5px;
	width: 6px;
	height: 6px;
	background: #fff;
	overflow: hidden;
	text-indent: -9999em;
	opacity: 0.5;
	border-radius: 50%;
}
.swiper-pagination-bullet-active{
	opacity: 1;
}
.swiper-button-next,.swiper-button-prev{
	color: white !important;
	width: 20px !important;
	height: 20px !important;
}
.swiper-button-next::after,.swiper-button-prev::after{
	font-size: 26px !important;
}
.promotion_info{
	img{
		display: block;
	}
	button{
		display: block;
		border:0px;
		padding:0px;
	}
}
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<>
		<QueryClientProvider client={queryClient}>
			<GlobalStyle/>
			<App />
		</QueryClientProvider>
	</>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
