import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Detail from './routes/detail';
import {Provider} from "react-redux"
import {createStore} from "redux"
import rootReducer from "./store/modules"
const store = createStore(rootReducer);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/detail/:id" element={<Detail/>}></Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
