import { useQuery } from "react-query"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { applyProductCoupon, applyUserCoupon, getContext } from "../../../server/api"
import Icons from "../../Icons"
import _ from "lodash";
import { changePriceFormat } from "../../../util"

const ModalWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10000;
  display: flex;
`

const ModalInner = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: #FFF;
  overflow: hidden;
`

const ModalHeader = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #DDD;
  position: relative; 
  p{
    margin: 0;
    line-height: 30px;
    font-weight: bold;
  }
`

const CloseBtn = styled.button`
  width: 50px;
  height: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
`

const ModalContent = styled.div`
  padding: 10px 20px;
  max-height: calc(100vh - 161px);
  overflow-x: hidden;
  overflow-y: auto;
`

const CouponBox = styled.div`
  cursor: pointer;
`

const CouponName = styled.div`
  padding: 0 16px;
  height: 40px;
  line-height: 18px;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #eee9de;
`

const CouponCont = styled.div`
  padding: 0 16px;
  border: 1px solid #e6e6e6;
  position: relative;
`

const CouponCost = styled.div`
  padding: 22px 0 0 0;
  line-height: 30px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
`

const CouponTerm = styled.div`
  padding: 4px 0 0 0;
  line-height: 18px;
  font-size: 13px;
  font-weight: 500;
  color: #9f9f9f;
`

const CouponStat = styled.div`
  margin-top: 20px;
  height: 45px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    line-height: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    em{
      span{
        font-weight: bold;
        color: #cd3d65;
      }
    }
  }
`

const DownIcon = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
`

/**
 * 쿠폰 모달
 * @param {*} param0 
 * @returns 
 */
const CouponModal = ({coupons,onModalHide}) => {
  const context = useSelector((state) => state.userState.context);
  const userIdx = useSelector((state) => state.userState.idx);
  const productIdx = useSelector((state) => state.productState.idx);
  const isCustomCoupon = () => {
    let customProductIds = [
      842, 843, 844, 845, 846, 847, 848,
      849, 850, 851, 852, 853, 854, 855,
    ]
    return _.indexOf(customProductIds,productIdx) > -1;
  }

  const applyCoupon = async (couponIdx) => {
    if (!isCustomCoupon()) {
      if(window.location.href.includes("tempToken")){
        const url = `https://slim9.biz/products/${productIdx}/coupons/${couponIdx}`
        try{
          await applyProductCoupon(url);
          alert("쿠폰이 발급되었습니다.");
        }catch(err){
          console.log(err);
        }finally{
          onModalHide()
        }
      }else{
        alert('로그인 후 이용해주세요.')
        return
      }
      
    }else{
      if(window.location.href.includes("tempToken")){
        try{
          await applyUserCoupon(couponIdx,userIdx)
          alert("쿠폰이 발급되었습니다.");
        }catch(err){
          console.log(err);
        }finally{
          onModalHide()
        }
      }else{
        alert('로그인 후 이용해주세요.')
        return
      }

    }
  }

  return (
    <ModalWrap>
      <ModalInner>
        <ModalHeader>
          <p>쿠폰 발급</p>
          <CloseBtn onClick={onModalHide}>x</CloseBtn>
        </ModalHeader>
        <ModalContent>
          <div>
            <ul>
              {
                coupons.map((item,index)=>(
                  <li key={index}>
                    <CouponBox>
                      <CouponName>{item.name}</CouponName>
                      <CouponCont>
                        <DownIcon onClick={() => applyCoupon(item.coupon_id)}>
                          <Icons url={'https://slim9.biz/assets/images/icon_down.png'}/>
                        </DownIcon>
                        <CouponCost>
                          <span>{item.amount}%</span>할인
                        </CouponCost>
                        <CouponTerm>
                          {changePriceFormat(item.minimum_order_amount)} 원 이상 구매 시 적용 가능
                        </CouponTerm>
                        <CouponStat>
                          {
                            item.issuable_term_text ?
                            <>
                            <p>발급기한</p>
                            <p>
                              <span>{item.issuable_term_text}</span>
                              (<em>
                                {
                                  item.issuable_remained_days ?
                                  <><span>{item.issuable_remained_days}일</span> 남음</>
                                  :
                                  null
                                }
                              </em>)
                            </p>
                            </>
                            :
                            null
                          }

                        </CouponStat>
                      </CouponCont>
                    </CouponBox>
                  </li>
                ))
              }

            </ul>
          </div>
        </ModalContent>
      </ModalInner>
    </ModalWrap>
  )
}

export default CouponModal