import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {useSelector} from "react-redux"
import { useQuery } from "react-query";
import { getProductItems } from "../../../server/api";
import _ from "lodash";
import BuyCart from "./buy_cart";

const Buy = styled.div`
  position: fixed;
  z-index: 102;
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(0,0,0,.1);
  z-index: 500;
  left: 0;
  top: 0;
  backdrop-filter: blur(4px);
  opacity: 0;
  animation: fadeInText 0.2s ease-out forwards;
  @keyframes fadeInText {
    100% {
        opacity: 1;
    }
  }
`

const BuyWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  padding: 40px 0 0 0;
  background-color: #ffffff;
  max-height: 50vh;
  /* box-shadow: 0 0 6px rgb(0 0 0 / 16%); */
  position: relative;
  z-index: 800;
  animation: fadeInUp 0.3s;
  @keyframes fadeInUp {
      0% {
          opacity: 0;
          transform: translate3d(0, 100%, 0);
      }
      to {
          opacity: 1;
          transform: translateZ(0);
      }
  }
`
const BuyBox = styled.div`
  flex: 1;
  padding: 0 20px 40px 20px;
  overflow-y: scroll;
`

const Option = styled.div``

const OptionItem = styled.dl`
  dt{
    margin: 0 0 15px 0;
    line-height: 18px;
    font-size: 13px;
    color: #9F9F9F;
  }

  dd{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 16px 0;
  }
`



const BtnOption = styled.label`
  margin: 0 10px 10px 0;
  cursor: pointer;
  vertical-align: middle;
  .button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    padding: 0 9.5px;
    position: relative;
    border: ${props => props.isBoard === 1 ? "1px solid #1f1f1f;" : "1px solid #efefef"};
  }
  .preview{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: ${props => props.isBoard === 1 ? "1px solid #1f1f1f;" : "1px solid #efefef"};
  }
  .previewColor{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    padding: 0 9.5px;
    position: relative;
    border: ${props => props.isBoard === 1 ? "1px solid #1f1f1f;" : "1px solid #efefef"};
  }
`
const PreviewImage = styled.i`
  width: 54px;
  height: 54px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${props => props.background});
`

const PreviewColor = styled.i`
  width: 28px;
  height: 28px;
  margin: 0 -7.5px;
  background-color: ${props => props.background};
`

const OptionSelect = styled.select`
  width: 100%;
  padding: 0 20px;
  height: 42px;
  font-size: 13px;
  font-weight: 500;
  color: #1f1f1f;
  appearance: none;
  border: 1px solid #1f1f1f;
  background: #fff url("https://slim9.biz/assets/images/icon_select_down.png") no-repeat right 10px center / 30px 30px;
`



//상품 옵션 선택 완료 리스트
const CartIn = styled.div``

const CartUl = styled.ul``

let selecting = [];



/**
 * 상품 옵션 선택 모달
 * @param {*} param0 
 * @returns 
 */
const BuyModal = ({options,productItem,hideModal}) => {
  const proudctIdx = useSelector((state) => state.productState.idx);
  const {data,isLoading} = useQuery(["productItem"],() => getProductItems(proudctIdx));
  const buyRef = useRef(null);

  const [products,setProducts] = useState([]);

  const [pItem,setPItem] = useState([]);
  useEffect(()=>{
    sessionStorage.setItem("options",JSON.stringify(options));
  },[])

  const resetPitem = () => {
    const history = JSON.parse(sessionStorage.getItem("options"));
    history.forEach((opt,i)=>{
      let selectable = _.uniq(data.map((item) => _.get(_.find(item.options, { name: opt.name }), 'value')).filter(q => q !== undefined))
      opt.selectable = opt.items.filter(q => selectable.indexOf(q.name) > -1)
      opt.selectable.forEach((q) => q.selected = false)
    })
    setPItem(history);
  }

  const handleDataChange = (newData) => {

    //console.log('업데이트받음');
    //console.log(newData);

    setProducts(newData);
  };

  useEffect(()=>{
    if(data !== undefined){
      resetPitem()
    }
  },[options,data])


  /**
   * 옵션이 없는 상품일 경우 예외처리
   */
  useEffect(()=>{
    if(!isLoading){
      if(data[0].options === 'null'){
        const newObj = {
          name : productItem.name,
          id : productItem.id,
          originId : productItem.origin_id,
          price : productItem.price,
          regularPrice : productItem.regular_price,
          options : [],
          quantity : 1,
          productItem : productItem.productItemIdxs,
          optionText : ""
        }
        setProducts((prev)=>{
          return [...prev,newObj];
        })
      }
    }
  },[data,isLoading])

  const refreshAvailableOptions = (itemOptions,items,selecting) => {
    const selected = itemOptions
        .map((q, i) => {
          return { name: q.name, value: selecting[i] || null }
        })
        .filter((q) => q.value)
    const available = []
    const q = _.filter(items, { options: selected }).map(q => q.options).map((q, i) => {
      return q.map(w => w.value)
    })

    _.range(0, q[0].length).forEach((w, i) => {
      available[i] = []
      q.forEach((e, i2) => available[i].indexOf(q[i2][i]) === -1 ? available[i].push(q[i2][i]) : null)
    })
    setPItem((prev)=>{
      let newArr = prev;
      newArr.map((item)=>{
        item.available = available;
        return item;
      })
      return [
        ...newArr
      ];
    })
  }
  //옵션 선택
  const optionClick = (opt,offset,event) => {
    if (event) {
      opt = _.find(opt.selectable, { name: event.target.value })
    }
    const selected = opt.selected
    const temp = options;
    const items = data;
    
    temp[offset].items.forEach(q => q.selected = false);
    opt.selected = !selected
    selecting[offset] = opt.selected ? opt.name : null;
    selecting.splice(0, selecting.length, ...selecting);
    let opts = temp.map((q,i) => {
      return {
        name : q.name,
        value : selecting[i]
      }
    })
    let found = _.find(data, { options: opts })
    if(!found){
      refreshAvailableOptions(temp,items,selecting);
      return;
    }
    if(products.length > 0){
      const find = products.findIndex((item) => item.productItem === found.id);
      if(find > -1){
        selecting = [];
        resetPitem()
        return alert('이미 등록된 상품 옵션입니다.')
      }
    }
    const newObj = {
      name : found.name,
      id : found.product_id,
      price : found.price + found.price_delta,
      regularPrice : productItem.regular_price,
      options : found.options,
      quantity : 1,
      productItem : found.id,
      categories : found.categories
    }

    selecting = [];
    setProducts((prev)=>{
      return [...prev,newObj];
    })
    resetPitem()
  }

  //외부 클릭
  useEffect(()=>{
    function handleClickOutside(e){
      if(buyRef.current && !buyRef.current.contains(e.target)){
        if(!e.target.className.includes("buys")){
          hideModal(false);
        }
      }
    }
    document.addEventListener("mousedown",handleClickOutside)
    return () => {
      document.removeEventListener("mousedown",handleClickOutside);
    }
  },[buyRef]);
  return (
    <Buy >
      <BuyWrap >
        <BuyBox ref={buyRef}>
          {
            pItem.length ? 
            <Option>
              <>
              {
                pItem.map((item,index)=>(
                  <OptionItem key={index}>
                    <dt>{item.name}</dt>
                    <dd>
                      {item.display_type == "select" ?
                        <OptionSelect onChange={($event) => optionClick(item,index,$event)}>
                          <option value="">옵션을 선택해주세요</option>
                          {
                            item.selectable.map((opt,optIdx)=>(
                              <option key={optIdx} disabled={item.available !== undefined && item.available[index] && item.available[index].indexOf(opt.name) === -1}>{opt.name}</option>
                            ))
                          }
                        </OptionSelect>
                      :null
                      }

                      {item.display_type === "button" ? 
                        item.selectable.map((opt,optIdx)=>(

                          item.available !== undefined && item.available.length > index && item.available[index].indexOf(opt.name) === -1 ?
                          null
                          :
                          <BtnOption key={optIdx} isBoard={item.items.length}>
                            <div className="button" onClick={() => optionClick(opt,index,null)}>
                              {opt.name}
                            </div>
                          </BtnOption>
                        ))
                      : null}

                      {
                        item.display_type === "preview" ?
                          item.selectable.map((opt,optIdx)=>(
                            item.available !== undefined && item.available.length > index && item.available[index].indexOf(opt.name) === -1
                            ?
                            null
                            : 
                            <BtnOption key={optIdx} isBoard={item.items.length} >
                              {
                                opt.url === undefined ? 
                                  <div key={optIdx} className="previewColor" onClick={() => optionClick(opt,index,null)}>
                                    <PreviewColor background={opt.code}/>
                                  </div>
                                : 
                                <div key={optIdx} className="preview" onClick={() => optionClick(opt,index,null)}>
                                  <PreviewImage background={opt.url}/>
                                </div>    
                              }
                            </BtnOption>
                          ))
                        : null
                      }
                    </dd>
                  </OptionItem>
                ))
              }
              </>
            </Option>
            :
            null
          }
          <CartIn>
            <CartUl>
              {
                products.length ? <BuyCart products={products} onDataChange={handleDataChange} itemDiscount={productItem.discount} price={productItem.price}/> : null
              }
            </CartUl>
          </CartIn>
        </BuyBox>
      </BuyWrap>
    </Buy>
  )
}

export default BuyModal;