import { useEffect, useState } from "react"
import {Star} from '@styled-icons/bootstrap/Star'
import {StarFill} from '@styled-icons/bootstrap/StarFill'
import styled from "styled-components"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
`
const StarOutLine = styled(Star)`
  color: #CD3D65;
  width: 15px;
  margin-left: 2px;
`
const StarColor = styled(StarFill)`
  color: #CD3D65;
  width: 15px;
  margin-left: 2px;
`

const Stars = styled.div`
  display: flex;
  .yellowStar {
    color: #CD3D65;
  }
  background: white;
`

/**
 * 상품 리뷰 상품 별점
 * @param {*} param0 
 * @returns 
 */
const ProductReviewStar = ({score}) => {
  const [rating,setRating] = useState([false,false,false,false,false]);
  useEffect(()=>{
    setRating((prev)=>{
      let newArr = [];
      let fScore = 5 - score;
      for(let i = 0; i < fScore; i++){
        newArr.push(false);
      }
      let tScore = 5 - fScore;
      for(let i = 0; i < tScore; i++){
        newArr.unshift(true);
      }
      return [...newArr];
    })
  },[score])
  return (
    <Wrap>
      <Stars>
        {
          rating.map((item,index)=>(
            <span key={index}>
              {item ? <StarColor/> : <StarOutLine/>}
            </span>
          ))
        }
      </Stars>
      
    </Wrap>
  )
}

export default ProductReviewStar;