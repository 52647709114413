import { useEffect, useState } from "react"
import styled from "styled-components"
import Icons from "../../Icons"

const Opti = styled.div`
padding: 15px;
position: relative;
`

const OptiWrap = styled.div`
display: ${(props) => props.isMore ? "block" : "none"};
`

const OptList = styled.dl`
display: flex;
align-items: flex-start;
height: 16px;
margin-top: 20px;
&:first-child{
  margin-top: 0px;
}
`


const OptTitle = styled.dt`
display: flex;
flex-shrink: 0;
min-width: 85px;
font-size: 12px;
font-weight: bold;
color: #707070;
margin: 0 20px 0 0;
`

const OptContent = styled.dd`
display: flex;
flex: 1;
font-size: 12px;
font-weight: bold;
color: #1F1F1F;
padding: 0 20px 0 0;
`

const BtnOpti = styled.a`
position: absolute;
top: 13px;
right: 13px;
width: 22px;
height: 22px;
`

/**
 * 상품 리뷰 리스트 옵션 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductReviewOption = ({option}) => {
  const [opt,setOpt] = useState("");
  const [isMore,setIsMore] = useState(false);
  useEffect(()=>{
    let temp = "";
    if(option.origin_attributes != null && option.origin_attributes.product_options !== undefined){
      const arr = Object.values(option.origin_attributes.product_options);
      if(arr.length){
        temp =`사이즈:${arr[0]} / 색상:${arr[1]}`
      }
    }

    setOpt(temp);
  },[])

  return (
    <Opti>
    <BtnOpti onClick={() => setIsMore(!isMore)}>
    {
      !isMore ? 
      <Icons url={'https://slim9-push.s3.ap-northeast-2.amazonaws.com/icon_more_down.png'}/>
      :
      <Icons url={'https://slim9-push.s3.ap-northeast-2.amazonaws.com/icon_more_up.png'}/>
    }

    </BtnOpti>
    <OptiWrap isMore={isMore}>
    <OptList>
    <OptTitle>사이즈 / 컵</OptTitle>
    <OptContent>
    { option.opt_girth != null ? option.opt_girth : '-' } / { option.opt_bra_cup != null ? option.opt_bra_cup : '-' }
    </OptContent>
    </OptList>
    
    <OptList>
    <OptTitle>몸무게 / 키</OptTitle>
    <OptContent>
    { option.opt_weight != null ? option.opt_weight : '-' } / { option.opt_height != null ? option.opt_height : '-' }
    </OptContent>
    </OptList>
    
    <OptList>
    <OptTitle>사이즈 어때요?</OptTitle>
    <OptContent>
    { option.opt_size != null ? option.opt_size : '-' }
    </OptContent>
    </OptList>

    <OptList>
    <OptTitle>선택한 옵션</OptTitle>
    <OptContent>
    { opt != "" ? opt : '-' }
    </OptContent>
    </OptList>
    </OptiWrap>


    </Opti>
    )
  }

  export default ProductReviewOption