
import { useEffect, useState } from "react"
import styled from "styled-components"
import { changePriceFormat } from "../../../util"
import Icons from "../../Icons"
import {useDispatch} from "react-redux"
import {UserProducts} from "../../../store/modules/user"
const CartLi = styled.li`
margin-top: 10px;
position: relative;
padding: 10px 15px;
border: 1px solid #efefef;
display: flex;
flex-direction: column;
.name{
  line-height: 18px;
  font-size: 13px;
  font-weight: 500;
  color: #1f1f1f;
  padding: 0 20px 0 0;
}
.options{
  font-weight: 400;
  color: #8F8F8F;
  padding: 0 20px 0 0;
  line-height: 18px;
  font-size: 13px;
}
`
const CartCont = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin: 9px 0 0 0;
`

const CartCount = styled.div`
display: flex;
align-items: center;
height: 24px;
line-height: 24px;
`
const CartBtn = styled.button`
position: relative;
cursor: pointer;
display: inline-block;
user-select: none;
-webkit-tap-highlight-color: transparent;
width: 24px;
height: 24px;
border: 1px solid #d5d5d5;
border-radius: 50%;
background-size: 12px auto;
background: white;
`

const CartInput = styled.input`
min-width: auto;
width: 32px;
margin: 0 2px;
text-align: center;
flex-shrink: 0;
border: none;
`

const CartPrice = styled.div`
line-height: 20px;
font-size: 15px;
font-weight: bold;
color: #1f1f1f;
`

const CartDelete = styled.div`
position: absolute;
top: 10px;
right: 10px;
width: 25px;
height: 25px;
`

const CartDiscount = styled.span`
text-decoration: line-through;
opacity: 0.3;
margin-right: 10px;
color: rgb(0, 0, 0);
`

const CartPriceText = styled.span`
color : ${(props) => props.isDiscount === "yes" ? "#cd3d65" : "black"};
`
const CartIcon = styled.img`
width: ${(props) => props.iconType === "plus" ? "14px" : "8px"};
height: ${(props) => props.iconType === "plus" ? "auto" : "2px"};
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`



/**
 * 상품 옵션선택->유저가 선택한 상품 리스트 컴포넌트
 * @param {} param0 
 * @returns 
 */
const BuyCart = ({products,onDataChange,itemDiscount,price}) => {
  const dispatch = useDispatch();
  const [cart,setCart] = useState([]);
  const setCartQuntity = (idx,type)=>{
    setCart((prev)=>{
      const newCart = prev;
      if(type == 'minus' && newCart[idx].quantity > 1){
        newCart[idx].quantity--;
      }else if(type == "plus"){
        newCart[idx].quantity++;
      }else{
        return newCart;
      }
      return [...newCart]
    })
  }

  const setCartDelete = (index) => {

    //console.log('삭제처리');

    //console.log(cart);

    //console.log(prev);

    const cartList = cart.filter((_, i) => i !== index);

    setCart(cartList);

    //console.log(cartList);

    onDataChange(cartList);
  }

  const checkDiscount = (item) => {
    const p1 = item.price * item.quantity;
    if(!itemDiscount){
      return p1;
    }
    let sum = 0;
    for(let item of products){
      sum += item.quantity
    }
    const discount = itemDiscount?.mass_data.find((data) => data.minimum <= sum && data.maximum >= sum);
    if(!discount){
      return p1;
    }
    const p2 = Number(price) + (item?.price_delta || 0)
    let p4 = p2 - (Number(discount.rate_type) ? p2 * (discount.amount * 0.01) : discount.amount)
    if (Number(discount.adjustment_unit) === 0) return Math.max(0, p4 * item.quantity)
      switch (discount.adjustment_method) {
      case 'rounddown':
        p4 = parseInt(Math.floor(p4 / Number(discount.adjustment_unit)) * Number(discount.adjustment_unit))
        break
      case 'round':
        p4 = parseInt(Math.round(p4 / Number(discount.adjustment_unit)) * Number(discount.adjustment_unit))
        break
      case 'roundup':
        p4 = parseInt(Math.ceil(p4 / Number(discount.adjustment_unit)) * Number(discount.adjustment_unit))
        break
      }
      return Math.max(0, p4 * item.quantity)
    }

    useEffect(()=>{
      setCart(products);
    },[products]);

    useEffect(()=>{
      const newArr = cart.map((item)=>{

        const newItem = {
          productId : item.id,
          productItemId : item.productItem,
          unitPrice : item.price,
          quantity : item.quantity,
          categories : item.categories
        }
        return newItem
      })
      dispatch(UserProducts(newArr));
    },[cart]);


    return (
      <>
      {
        cart.length ? cart.map((item,index) => (
          <CartLi key={index}>
          <div className="name">
          {item.name}
          </div>
          <div className="options">
          {
            item.options.map((opt,oIdx)=>(
            <span key={oIdx}>
            {opt.name} : {opt.value}
            {oIdx !== (item.options.length-1) ? " / " : null}
            </span>
            ))
          }
          </div>  
          <CartCont>
          <CartCount>
          <CartBtn onClick={() => setCartQuntity(index,'minus')}>
          <CartIcon src="https://slim9.biz/assets/images/icon_minus.png" iconType={'minus'}/>
          </CartBtn>
          <CartInput readOnly value={item.quantity}/>
          <CartBtn onClick={() => setCartQuntity(index,'plus')}>
          <CartIcon src="https://slim9.biz/assets/images/icon_plus.png" iconType={'plus'}/>
          </CartBtn>
          </CartCount>
          <CartPrice>
          <CartDiscount>{checkDiscount(item) !== item.quantity * item.price ? changePriceFormat(item.quantity * item.price) : null}</CartDiscount>
          <CartPriceText isDiscount={checkDiscount(item) !== item.quantity * item.price ? "yes":"no"}>
          {changePriceFormat(checkDiscount(item))}원
          </CartPriceText>
          </CartPrice>  
          </CartCont>
          <CartDelete onClick={() => setCartDelete(index)}>
          <Icons url={'https://slim9.biz/assets/images/icon_close.png'}/>
          </CartDelete>
          </CartLi> 
          )) : null
      }

      </>
      )
    }

    export default BuyCart;