// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination ,Lazy} from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import styled from 'styled-components';
import CommonImage from './common_image';
import { useRef } from 'react';

SwiperCore.use([Navigation, Pagination,Lazy]);


const SlideWrap = styled.div`
  width: 100%;
  height: 100vw;
`
// lazy={true}

/**
 * 페이지 상단 메인 배너
 * @param {*} param0 
 * @returns 
 */
const ProductSlide = ({images}) => {
  return (
    <>
        <Swiper
        slidesPerView={1}
        navigation
        lazy={{loadPrevNext: true}}
        pagination={{ clickable: true }}
        loop={true}
        >
          {
            images.map((item,index) => (
              <SwiperSlide key={index}>
                <SlideWrap>
                  <CommonImage url={item} radius={'0px'}/>
                </SlideWrap>
              </SwiperSlide>
            ))
          }
        </Swiper>
    </>
  );
}

export default ProductSlide;