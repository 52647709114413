import styled from "styled-components"

const LayerWrap = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 140px;
  height: auto;
  padding: 10px 18px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  z-index: 4;
  display: flex;
  flex-direction: column;
  span{
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 13px;
    font-weight: 500;
    color: #303030;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
  }
`

/**
 * 팝업 메뉴 버튼
 * @returns 
 */
const Layer = () => {
  return (
    <LayerWrap>
      <span>게시물 신고하기</span>
      <span>사용자 차단하기</span>
    </LayerWrap>
  )
}
export default Layer;