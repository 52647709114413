import Header from "../component/header";
import ProductSlide from "../component/product/product_slide";
import ProductInfo from "../component/product/product_info";
import ProductReviewOverview from "../component/product/overview/product_review_overview";
import ProductDelivery from "../component/product/product_delivery";
import ProductReview from "../component/product/review/product_review";
import BuyBottom from "../component/product/order/buy_bottom";
import ProductQna from "../component/product/qna/product_qna";
import ProductRefund from "../component/product/product_refund";
import ProductTab from "../component/product/product_tab";
import ProductDetail from "../component/product/product_detail";

import loading from "../assets/image/loading.svg";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { getProduct,countProductView, getContext, getCartCount } from "../server/api";
import {useQuery } from "react-query"
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import ProductRelation from "../component/product/relation/proudct_relation";
import { UserContext, UserIdx } from "../store/modules/user";
import { ProductIdx } from "../store/modules/product";


const Wrapper = styled.div`
  background-color: white;
  padding-bottom: 60px;
`

const ProductDetailWrap = styled.div`
  border-bottom: 8px solid #efefef;
  background: #ffffff;
`

const Loadding = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
`

const Detail = () => {
  const {id} = useParams();
  const [uIdx,setUIdx] = useState(0);

  const userIdx = useSelector((state) => state.userState.idx);

  const {data : count} = useQuery(["cartCount"],() => getCartCount(id,uIdx),{
    enabled:false
  })

  const {data:product,refetch:productRefetch} = useQuery(["product"],() => getProduct(id,uIdx),{
    enabled:false
  })
  const {data:productView,refetch:productViewRefetch} = useQuery(["productView"],() => countProductView(id,uIdx),{
    enabled:false
  })

  const {data:context,refetch:contextRefetch} = useQuery(["context"], () => getContext(),{
    enabled:false
  });

  const [value, setValue] = useState(0);

  const cartCountValue = () => {
    setValue(count + 1);
  };

  const dispatch = useDispatch();
  useEffect(()=>{
    if(window.location.href.includes("tempToken")){
      const querys = window.location.href.split("?");
      const tempToken = querys[1].split("tempToken=")[1]
      if(tempToken !== ""){
        const userIdx = Number(atob(tempToken));
        setUIdx(userIdx);
        dispatch(UserIdx(userIdx))
      }else{
        alert("로그인 이후 이용 가능합니다.");
        window.location.href="https://slim9.biz/login"
      }
    }else{
      alert("잘못된 접근 입니다.");
      window.location.href="https://slim9.biz"
    }
    dispatch(ProductIdx(id))
  },[])
  useEffect(()=>{
    if(uIdx !== 0){
      productRefetch();
      productViewRefetch();
      contextRefetch();
      setValue(count);
    }
  },[uIdx])

  useEffect(()=>{
    dispatch(UserContext(context))
  },[context])
  return (
      <>
        <Wrapper>
            {
              product === undefined ? 
              <Loadding>
                <img src={loading} alt="loading.."/>
              </Loadding>
              :
              <>
                <Header cartCount={value} />
                <ProductSlide images={product.item.images}/>
                <ProductInfo name={product.item.name} price={product.item.price} regularPrice={product.item.regular_price}/>
                <ProductReviewOverview/>
                <ProductDelivery/>
                <ProductDetailWrap>
                  <ProductTab/>
                  <ProductDetail description={product.item.description} size={product.item.size_description}/>
                  <ProductReview/>
                  <ProductRelation/>
                  <ProductQna/>  
                </ProductDetailWrap>
                <BuyBottom options={product.item.item_options} productItem={product.item} product={product} cartCount={cartCountValue} />
                <ProductRefund/>
              </>
            }
        </Wrapper>

      </>
  );
}
export default Detail;


