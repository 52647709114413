
import styled from "styled-components";
import ProductOverviewReviewItem from "../overview/product_review_o_item";
import {useQuery } from "react-query"
import { getProductOverViewReview } from "../../../server/api";

import {useSelector} from "react-redux"


import { useEffect, useState } from "react";
import { Review,Head,HeadImg,HeadNum,Total,Cont } from "./overview_style";
import OverviewSkeleton from "./overview_skeleton";



/**
 * 페이지 상단 리뷰 컴포넌트
 * @returns 
 */
const ProductReviewOverview = ()=>{

  const proudctIdx = useSelector((state) => state.productState.idx);
  const {data,isLoading} = useQuery(["productOverviewReview"],()=>getProductOverViewReview(proudctIdx));
  // const [isSkeleton,setIsSkeleton] = useState(true);


  // useEffect(()=>{
  //   if(!isLoading){
  //     setTimeout(()=>{
  //       setIsSkeleton(false);
  //     },500)
  //   }
  // },[isLoading])
  return (
    <div>
      {isLoading? 
      <OverviewSkeleton/>
      : 
      <Review>
        <Head>
          <HeadImg src="https://slim9.biz/assets/images/icon_review.png"/>
          <HeadNum>{data.info.score}</HeadNum>
          <Total>리뷰 <span>{data.info.reviewCnt}</span>개</Total>
        </Head>
        <Cont>
          리뷰를 작성한 구매자 중 <b>90%</b>가 만족한 상품이에요.
        </Cont>
        <ProductOverviewReviewItem reviewList={data.list}/>
      </Review>
      }
    </div>
  );
}

export default ProductReviewOverview;