const PRODUCTIDX = "PRODUCTIDX"

export const ProductIdx = (idx) => {
  return {
    type : PRODUCTIDX,
    payload:{
      idx
    }
  }
}

const initialState = {
  idx : 0
}

export default function productState(state = initialState,action){
  switch(action.type){
    case PRODUCTIDX:
      return {
        idx : Number(action.payload.idx)
      }
    default:
      return state;
  }
}