import styled from "styled-components";

export const Review = styled.div`
  padding: 30px 20px 40px;
  border-bottom: 1px solid #efefef;
`

export const Head = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
`

export const HeadImg = styled.img`
  margin: -3px 3px 0 0;
  width: 18px;
  height: 18px;
`

export const HeadNum = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #1f1f1f;
`

export const Total = styled.span`
  margin-left: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #303030;
`

export const Cont = styled.div`
  margin: 0 0 10px 0;
  line-height: 20px;
  font-size: 14px;
  color: #303030;
  b{
    font-weight: bold;
  }
`

export const List = styled.div`
  margin: 0 -20px;
`

export const ListUl = styled.ul`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 20px;
`

export const ListItem = styled.li`
  margin: 0 15px 0 0;
  width: 275px;
`

export const ListA = styled.a`
  display: flex;
  padding: 14px 13px;
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 12%);
  overflow: hidden;
  color: #222;
  text-decoration: none;
`

export const ListImg = styled.div`
  flex-shrink: 0;
  width: 57px;
  height: 57px;
`
export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
export const Con = styled.div`
  width: calc(100% - 57px);
  padding-left: 10px;
`

export const ConTitle = styled.strong`
  display: block;
  margin: 0 0 5px 0;
  line-height: 18px;
  font-size: 13px;
  font-weight: bold;
  color: #1f1f1f;
`

export const ConBody = styled.p`
  line-height: 18px;
  font-size: 13px;
  color: #1f1f1f;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`