import { useState } from "react";
import styled from "styled-components";
import { checkDevice } from "../../../util";
import Layer from "../../layer";
import ProductReviewContent from "./product_review_content";
import ProductReviewHelp from "./product_review_help";
import ProductReviewOption from "./product_review_option";
import ProductReviewStar from "./product_review_star";

const Item = styled.li`
  position: relative;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

const Head = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  line-height: 20px;
  border-bottom: 1px solid #efefef;
`

const HeadWrap = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 13px;
  font-weight: bold;
  color: #9F9F9F;
  img{
    margin-left: 6px;
    margin-top: -3px;
    height: 20px;
  }
  strong{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #1F1F1F;
  }
`

const Report = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;
  button{
    width: 35px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    img{
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
`

const ReportIcon = styled.img`
  width: ${(props)=>props.isDevice == "android" ? "0px" : "20px"};;
`




/**
 * 상품 리뷰 아이템 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductReviewItem = ({review}) => {
  const [isLayer,setIsLayer] = useState(false);

  return (
    <Item>
      <Head>
        <HeadWrap>
          평점
          {/* <img src={`https://slim9.biz/assets/images/icon_star${review.score}.png`} loading="lazy" width={100} height={20}/> */}
          <ProductReviewStar score={review.score}/>
          <strong>{review.scoreTxt}</strong> 
        </HeadWrap>
        <Report>
          <button onClick={() => setIsLayer(!isLayer)}>
            <ReportIcon isDevice={checkDevice()} width={23} height={23} src="https://slim9.biz/assets/images/icon_dott.png" loading="lazy"/>
          </button>
          {isLayer ? <Layer/> : null}
          
        </Report>
      </Head>

      <ProductReviewOption option={review}/>
      <ProductReviewContent review={review}/>
      <ProductReviewHelp review={review}/>
    </Item>
  )
}

export default ProductReviewItem;