import styled from "styled-components"
import {useQuery} from "react-query"
import { getRelactionProduct } from "../../../server/api";
import {useSelector} from "react-redux"
import ProductItem from "../common/product_item";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import RelationSkeleton from "./retation_skeleton";

const DetailRelation = styled.div`
  overflow: hidden;
  font-family: 'Noto Sans KR';
  padding: 20px;
`
const RelactionTitle = styled.div`
  margin: 16px 0 30px 0;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  font-weight: bold;
  color: #1f1f1f;
`

const Relaction = styled.div`
  margin: 30px 0;
  position: relative;
`

const ProductList = styled.ul`
  margin: 0 -5px;
  display: flex;
  overflow-x: auto;
`


/**
 * 관련 상품 컴포넌트
 * @returns 
 */
const ProductRelation = () => {
  const productIdx = useSelector((state) => state.productState.idx);
  const userIdx = useSelector((state) => state.userState.idx)
  const {data,isLoading} = useQuery(["relactionProduct"],() => getRelactionProduct(productIdx,userIdx))
  return (
    <div id="productRelation">
      <DetailRelation>
        <RelactionTitle>관련 상품</RelactionTitle>
        <Relaction>
          <ProductList>
            {isLoading ? 
            [1,2,3].map((item,index)=>(
              <RelationSkeleton key={index}/>
            ))
            : 
              data.map((item,index) => (
                <ProductItem key={index} item={item}/>
              ))
            }
          </ProductList>
        </Relaction>
      </DetailRelation>
    </div>
  )
}

export default ProductRelation;