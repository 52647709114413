
import { useEffect, useState } from "react";
import styled from "styled-components";
import Icons from "../../Icons";
import {useDispatch} from "react-redux"
import { ReviewSearch } from "../../../store/modules/review";

const Tool = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -20px;
  padding: 0 20px;
  height: 60px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #9F9F9F;
  height: 20px;
  padding-left: 20px;
`
const Select = styled.select`
  position: relative;
  display: flex;
  align-items: center;
  width: 65px;
  font-size: 13px;
  color: #9F9F9F;
  border: none;
  margin-right: 10px;
  background: transparent;
`
const Right = styled.div`
  display: flex;
  align-items: center;
`

const RightWrap = styled.div`
  display: flex;
`

const BtnGath = styled.button`
  display: flex;
  align-items: center;
  background: white;
  border: none;
  p{
    line-height: 20px;
    font-size: 14px;
    color: #707070;
  }
`

const BtnIcon = styled.div`
  width: 16px;
  height: 16px;
  margin: -3px 3px 0 0;
`

const SearchBtn = styled.button`
  display: flex;
  align-items: center;
  background: white;
  border: none;
`

const BodyWrap = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #efefef;
  margin: 0 -20px;
  padding: 0 20px;
  padding-left: 20px;
`

const BodySelect = styled.select`
  padding: 0 26px 0 13px;
  height: 32px;
  min-width: 60px;
  border: 1px solid #efefef;
  appearance: none;
  background: url('https://slim9.biz/assets/images/icon_select_down.png') no-repeat right 6px center / 18px auto;
  margin-left: ${(props)=>props.index != 0 ? "10px" : "0px"};
`



/**
 * 상품 리뷰 검색 컴포넌트
 * @returns 
 */
const ProductReviewSearch = () => {
  const [search,setSearch] = useState({});
  const [orders,setOrders] = useState("popular");
  const [types,setTypes] = useState("text");
  const [height,setHeight] = useState("");
  const [weight,setWight] = useState("");
  const [girth,setGirth] = useState("");
  const dispatch = useDispatch();
  const typeChange = () => {
    if(types == "text"){
      setTypes("photo")
    }else{
      setTypes("text")
    }
  }
  useEffect(()=>{
    setSearch((prev) => {
      let newItem = prev;
      newItem.orders = orders;
      newItem.types = types;
      newItem.height = height;
      newItem.weight = weight;
      newItem.girth = girth;
      return {...newItem}
    })
  },[orders,types,height,weight,girth]);

  useEffect(()=>{
    dispatch(ReviewSearch(search));
  },[search])

  return (
    <>
      <Tool>
        <Left>
          <Select value={orders} onChange={(e) => setOrders(e.target.value)}>
            <option value="popular">인기순</option>
            <option value="date">등록순</option>
            <option value="score">평점순</option>
          </Select>
        </Left>
        <Right>
          <RightWrap>
            <BtnGath onClick={typeChange}>
              <BtnIcon >
                {
                  types == "text" ? 
                  <Icons url={'https://slim9.biz/assets/images/circle-unchecked.jpg'}/>
                  :
                  <Icons url={'https://slim9.biz/assets/images/circle-checked.jpg'}/>
                }
              </BtnIcon>
              <p>포토/동영상 모아보기</p>
            </BtnGath>
            <SearchBtn>
              <BtnIcon>
                <Icons url={'https://slim9.biz/assets/images/icon_zoom.png'}/>
              </BtnIcon>
              <p>검색</p>
            </SearchBtn>
          </RightWrap>
        </Right>
      </Tool>
      <BodyWrap>
        <BodySelect index={0} value={height} onChange={(e) => setHeight(e.target.value)}>
          <option value="">키</option> 
          <option value="0,149">149cm 이하</option>
          <option value="150,152">150 - 152cm</option>
          <option value="153,155">153 - 155cm</option>
          <option value="156,158">156 - 158cm</option>
          <option value="159,161">159 - 161cm</option>
          <option value="162,164">162 - 164cm</option>
          <option value="165,167">165 - 167cm</option>
          <option value="168,170">168 - 170cm</option>
          <option value="171,173">171 - 173cm</option>
          <option value="174,176">174 - 176cm</option>
          <option value="177,179">177 - 179cm</option>
          <option value="180,182">180 - 182cm</option>
          <option value="183,185">183 - 185cm</option>
          <option value="186,188">186 - 188cm</option>
          <option value="189,191">189 - 191cm</option>
          <option value="192,999">192cm 이상</option>
        </BodySelect>

        <BodySelect index={1} value={weight} onChange={(e) => setWight(e.target.value)}>
          <option value="">몸무게</option> 
          <option value="0,44">44kg 이하</option>
          <option value="45,49">45 - 49kg</option>
          <option value="50,54">50 - 54kg</option>
          <option value="55,59">55 - 59kg</option>
          <option value="60,64">60 - 64kg</option>
          <option value="65,69">65 - 69kg</option>
          <option value="70,74">70 - 74kg</option>
          <option value="75,79">75 - 79kg</option>
          <option value="80,84">80 - 84kg</option>
          <option value="85,89">85 - 89kg</option>
          <option value="90,94">90 - 94kg</option>
          <option value="95,99">95 - 99kg</option>
          <option value="100,104">100 - 104kg</option>
          <option value="105,109">105 - 109kg</option>
          <option value="110,114">110 - 114kg</option>
          <option value="115,119">115 - 119kg</option>
          <option value="120,124">120 - 124kg</option>
          <option value="125,129">125 - 129kg</option>
          <option value="130,134">130 - 134kg</option>
          <option value="135,139">135 - 139kg</option>
          <option value="140,144">140 - 144kg</option>
          <option value="145,149">145 - 149kg</option>
          <option value="150,999">150kg 이상</option>
        </BodySelect>

        <BodySelect index={2} value={girth} onChange={(e) => setGirth(e.target.value)}>
          <option value="">가슴둘레</option> 
          <option value="60">60</option>
          <option value="65">65</option>
          <option value="70">70</option>
          <option value="75">75</option>
          <option value="80">80</option>
          <option value="85">85</option>
          <option value="90">90</option>
          <option value="95">95</option>
          <option value="100">100</option>
        </BodySelect>
      </BodyWrap>
    </>
  )
}

export default ProductReviewSearch;