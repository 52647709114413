import CommonImage from "../common_image";
import { List,ListUl ,ListItem,ListA,ListImg,Con,ConTitle,ConBody} from "./overview_style";

/**
 * 상품 상단 리뷰 아이템
 * @param {*} param0 
 * @returns 
 */
const ProductOverviewReviewItem = ({reviewList}) => {
  const showReview = (idx) => {
    window.location.href = `https://slim9.biz/reviews/${idx}`;
  }
  return (
    <List>
      <ListUl>
        {
          reviewList.map((item,index) => (
            <ListItem key={index} onClick={() => showReview(item.id)}>
              <ListA>
                
                {
                  item.origin_attributes.images !== undefined ?
                  <ListImg>
                    <CommonImage url={item.origin_attributes.images[0].thumbnail_url} radius={'0px'}/>
                  </ListImg>
                  :
                  <ListImg></ListImg>
                }
                <Con>
                  <ConTitle>{item.scoreTxt}</ConTitle>
                  <ConBody>
                    {item.contents}
                  </ConBody>
                </Con>
              </ListA>
            </ListItem>
          ))
        }
        
      </ListUl>
    </List>
  )
}

export default ProductOverviewReviewItem;