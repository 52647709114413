
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getProductReview } from "../../../server/api";
import ProductReviewCount from "./product_review_count";
import ProductReviewItem from "./product_review_item";
import {useSelector} from "react-redux"
import ProductReviewSearch from "./product_review_search";
const ReviewWrap = styled.div`
  padding: 0 20px;
`
const ReviewTitle = styled.div`
  margin: 16px 0 30px 0;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  font-weight: bold;
  color: #1f1f1f;
`

const ReviewPage = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`

const ReviewPageBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 113px;
  padding: 0 10px;
  height: 35px;
  font-size: 13px;
  color: ${props=>props.disabled? '#ddd': '#303030' };
  border: 1px solid #efefef;
  background: #fff;
  text-align: center;
`

const ReviewPageTitle = styled.div`
  margin-top: 10px;
`



/**
 * 상품 리뷰 컴포넌트
 * @returns 
 */
const ProductReview = () => {
  const search = useSelector((state) => state.reviewState.search);
  const productIdx = useSelector((state) => state.productState.idx);
  const status = useSelector((state) => state.reviewState.toggle);
  const [page,setPage] = useState(1);
  const fetchReview = (page = 1) => getProductReview(productIdx,page,search) 
  const {
    isLoading,
    data,
    } = useQuery({
    queryKey: ['productReview', page,search,status],
    queryFn: () => fetchReview(page,search),
    keepPreviousData : true,
    enabled:true
  });


  return (
    <div id="productReview">
      <div id="detail_review">
        <ReviewWrap>
          <ReviewTitle>상품 리뷰</ReviewTitle>
          <ProductReviewSearch/>
          {
            isLoading ? 
            <div>Loading...</div>
            : 
            <>
              <ProductReviewCount statistics={data.statistics}/>
              <div>
                <ul>
                  {data.list.map((item,index)=>(
                    <ProductReviewItem review={item} key={index}>
                    </ProductReviewItem>
                  ))}
                </ul>
              </div>

              <div>
                <div>
                  <ReviewPage>
                    <ReviewPageBtn disabled={page === 1 ? true : false} onClick={() => setPage(page-1)}> 이전 </ReviewPageBtn>
                    <ReviewPageTitle>
                      <span>{page}</span> / <span>{data.size}</span>
                    </ReviewPageTitle>
                    <ReviewPageBtn disabled={page === data.size ? true : false} onClick={() => setPage(page+1)}> 다음 </ReviewPageBtn>
                  </ReviewPage>
                </div>
              </div>
            </> 
          }
        </ReviewWrap>
      </div>

    </div>
  )
}

export default ProductReview;