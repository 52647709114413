import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setToggleFavorite } from "../../../server/api";
import Icons from "../../Icons";


/**
 * 상품 리스트 찜 버튼
 * @param {*} param0 
 * @returns 
 */
const ItemFavorite = ({useFavorite,productIdx}) => {
  const userIdx = useSelector((state) => state.userState.idx)
  const [isFavorite,setIsFavorite] = useState(false);
  const toggleFavorite = async() => {
    const {success} = await setToggleFavorite(userIdx,productIdx,isFavorite)
    if(success){
      setIsFavorite(!isFavorite);
    }
  }

  useEffect(()=>{
    useFavorite == null ? setIsFavorite(false) : setIsFavorite(true);
  },[])
  return (
    <>
      <span onClick={toggleFavorite}>
        {
          !isFavorite  ? 
          <Icons url={'https://slim9.biz/assets/images/icon_zzim.png'}/> : 
          <Icons url={'https://slim9.biz/assets/images/icon_zzim_active.png'}/>
        }
      </span>
      
    </>
  );
}

export default ItemFavorite;