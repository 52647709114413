import styled from "styled-components"
import {Link} from "react-scroll"


const ProductTabWrap = styled.div`
  position: sticky;
  padding: 0 20px;
  top: 52px;
  border-bottom: 1px solid #efefef;
  z-index: 20;
  background: #fff;
`

const ProductTabList = styled.ul`
  display: flex;
`
const ProductTabListItem = styled.li`
  flex: 1;
`

const NavLinks = styled(Link)`
  span{
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    color: #9F9F9F;
  }
  &.active{
    span{
      font-weight: bold;
      color: #1f1f1f;
      border-color: #1f1f1f;
    }
  }
`

/**
 * 상품 상세 탭 컴포넌트
 * @returns 
 */
const ProductTab = () =>{
  return (
    <ProductTabWrap>
      <ProductTabList>
        <ProductTabListItem>
          <NavLinks to="productDtail" offset={-100} spy={true} smooth={true} activeClass='active'>
            <span>상세정보</span>
          </NavLinks>
        </ProductTabListItem>
        <ProductTabListItem>
          <NavLinks to="productReview" offset={-110}  spy={true} smooth={true} activeClass='active'>
            <span>리뷰</span>
          </NavLinks>
        </ProductTabListItem>
        <ProductTabListItem>
          <NavLinks to="productRelation"  offset={-200}  spy={true} smooth={true} activeClass='active'>
            <span>관련상품</span>
          </NavLinks>
        </ProductTabListItem>
        <ProductTabListItem>
          <NavLinks to="productQna" offset={-300} spy={true} smooth={true} activeClass='active'>
            <span>Q&A</span>
          </NavLinks>
        </ProductTabListItem>
      </ProductTabList>
    </ProductTabWrap>
  )
}

export default ProductTab;