const SETUSER = "SETUSER"
const USERPRODUCTS = "USERPRODUCTS";
const CONTEXT = "CONTEXT"


export const UserIdx = (idx) => {
  return{
    type : SETUSER,
    payload : {
      idx
    }
  }
}
export const UserProducts = (list) => {
  return {
    type : USERPRODUCTS,
    payload : {
      list
    }
  }
}

export const UserContext = (context) => {
  return {
    type : CONTEXT,
    payload : {
      context
    }
  }
}



const initialState = {
  idx : 0,
  list : [],
  context : CONTEXT
}

export default function userState(state = initialState,action){
  switch(action.type){
    case SETUSER:
      return{
        ...state,
        idx : action.payload.idx,
      }
    case USERPRODUCTS:
      return{
        ...state,
        list : action.payload.list
      }
    case CONTEXT:
      return{
        ...state,
        context : action.payload.context
      }
    default:
      return state;
  }
}