import { useState } from "react";
import styled from "styled-components";
const QnaItem = styled.li`
  border-bottom: 1px solid #efefef;
  position: relative;
`
const QnaOpen = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0;
  min-height: 50px;
  background: transparent;
  border: none;
`

const QnaTit = styled.div`
  display: flex;
  align-items: flex-start;
  img{
    margin: 2px 10px 2px 0;
    width: 16px;
    height: auto;
  }
  span{
    flex: 1;
    font-size: 13px;
    line-height: 20px;
    color: #1f1f1f;
    text-align: left;
  }
`

const QnaTxt = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  p{
    font-size: 13px;
    font-weight: 500;
  }
  .complete{
    color: #CD3D65;
  }
  .gray{
    margin-left: 13px;
    display: flex;
    align-items: center;
    em{
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      color: #9F9F9F;
    }
    .date::before{
      content: '';
      margin: 0 8px;
      width: 1px;
      height: 10px;
      background: #9F9F9F;
      display: inline-flex;
    }
  }
`

const QnaCont = styled.div`
  background: #F7F7F7;
  padding: 20px 17px;
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
  color: #444444;
`


const QnaReport = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: absolute;
  top: 15px;
  right: 20px;
`
const QnaReportBtn = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0px;
  img{
    display: block;
    width: 100%;
    height: auto;
  }
`

const QnaLayer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 140px;
  height: auto;
  padding: 10px 18px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  z-index: 4;
  display: flex;
  flex-direction: column;
  display: block;
  a{
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 13px;
    font-weight: 500;
    color: #303030;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
  }
`


/**
 * 상품 Qna 리스트 아이템 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductQnaItem = ({item}) => {
  const [isQna,setIsQna] = useState(false);
  const [rePort,setRePort] = useState(false);
  return (
    <QnaItem>
      <QnaOpen onClick={() => setIsQna(!isQna)}>
        <QnaTit>
          <img src="https://slim9.biz/assets/images/icon_lock.png" loading="lazy"/>
          <span>
            [<span>{item.category}</span>]
            <span>비공개상담입니다.</span>
          </span>
        </QnaTit>
        <QnaTxt>
          <p className="complete">답변완료</p>
            <p className="gray">
            <em>{item.name}</em>
            <em className="date">{item.create_date}</em>
          </p>
        </QnaTxt>
      </QnaOpen>
      {
        isQna ?
        <QnaCont>
          <div>
            <p>비공개 상담입니다.</p>
          </div>
        </QnaCont>
        : null
      }
      <QnaReport>
        <QnaReportBtn onClick={() => setRePort(!rePort)} >
          <img src="https://slim9.biz/assets/images/icon_dott.png" loading="lazy"/>
        </QnaReportBtn>
        {
          rePort ?
          <QnaLayer>
            <a>게시물 신고하기</a>
            <a>사용자 차단하기</a>
          </QnaLayer>
          : null
        }
        
      </QnaReport>
    </QnaItem>
  )
}
export default ProductQnaItem;