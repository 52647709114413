export function changePriceFormat(price){
  const num = Number(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num;
}

export function checkDevice(){
  let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
 
  if ( varUA.indexOf('android') > -1) {
      //안드로이드
      return "android";
  } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
      //IOS
      return "ios";
  } else {
      //아이폰, 안드로이드 외
      return "android";
  }
}


//logEvent("food","{\"foo\":\"bar\", \"baz\": \"123\"}")
export function logEvent(name,params){
  if (!name) {
      return;
  }
  console.log("Ga Log event");
  if(typeof window.webkit !== "undefined"){
    window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({"action":"logEvent","name" :name,"params":JSON.stringify(params)}))
  }
  if (typeof window.AnalyticsWebInterface !== "undefined") {
      // Call Android interface

      console.log("Android Call")

      //안드로이드는 네이티브에 등록한 AnalyticsWebInterface로 이벤트 전송
      window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
      
  } else if (typeof window.webkit !== "undefined"
      && typeof window.webkit.messageHandlers !== "undefined"
      && typeof window.webkit.messageHandlers.firebase !== "undefined"
      && typeof window.webkit.messageHandlers.cordova_iab !== "undefined") {
      console.log("Ios Call")
      // Call iOS interface
      // var message = {
      // command: 'logEvent',
      // name: name,
      // parameters: params
      // };

      // "{\"foo\":\"bar\", \"baz\": \"123\"}"
      // objective c 에 딕셔너리 구조 특성으로 인해 위와 같은 구조로 전송해야된다
      let message = 'logEvent' + "+" + name + "+" + params

      //ios 같은경우는 webkit cordova_iab으로 메세지 전송
      window.webkit.messageHandlers.cordova_iab.postMessage(message);
  } else {
      // No Android or iOS interface found
      console.log("No native APIs found.");
  }
}

//앱스플라이어 이벤트 전송

export function recordEvent(eventName,eventParams) {

  console.log("recordEvent")
  console.log(eventName);
  console.log(eventParams)
  
  // var eventName = "af_purchase";
  // var eventParams = "{\"af_revenue\":\"6.72\", \"af_content_type\": \"wallets\", \"af_content_id\": \"15854\"}";
  // objective c 에 딕셔너리 구조 특성으로 인해 위와 같은 구조로 전송해야된다
  
  let phone = navigator.userAgent.toLowerCase();
  if(phone.indexOf("android") > -1){
    //안드로이드는 네이티브에 등록한 App 객체로 전송
    if(typeof app !== "undefined"){
      window.app.recordEvent(eventName, eventParams);
    }
  }else if(phone.indexOf("iphone") > -1 || phone.indexOf("ipad") > -1 || phone.includes("ipod") > -1){
  //ios 는 cordova iab에 메세지 전송
    if(typeof window.webkit !== "undefined" 
    && typeof window.webkit.messageHandlers !== "undefined" 
    && typeof window.webkit.messageHandlers.cordova_iab !== "undefined"){
      window.webkit.messageHandlers.cordova_iab.postMessage(eventName + "+" + eventParams)
    }
  }else{
    console.log("ohter")
  }
}
