import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkaletonWrap = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0,0,0);
  width: 150px;
  margin-right: 10px;
`
const SkaletonImg = styled(Skeleton)`
  position: relative;
  display: block;
  width: 150px;
  height: 200px;
`

const SkaletonCon = styled.div`
  position: relative;
  margin: -5px 0 0 0;
  padding: 0 0px;
  display: block;
`

const SkaletonName = styled(Skeleton)`
  height: 17px;
  width: 100%;
`
const SkaletonPrice = styled(Skeleton)`
  width: 100%;
  width: 100%;
  margin-top: 10px;
`


/**
 * 관련 상품 스켈레톤 Ui
 * @returns 
 */
const RelationSkeleton = () => {
  return (
    <SkaletonWrap>
      <SkaletonImg></SkaletonImg>
      <SkaletonCon>
        <SkaletonName/>
        <SkaletonPrice/>
      </SkaletonCon>
    </SkaletonWrap>
  )
}

export default RelationSkeleton;