import { useState } from "react";
import { useEffect, useRef } from "react"
import styled from "styled-components"
import Skeleton from "react-loading-skeleton";


const ImageWrap = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${(props) => props.radius};
`

const SkeletonImage = styled(Skeleton)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${(props) => props.radius};
`

/**
 * 공통 이미지 컴포넌트(Lazy 로딩 적용)
 * @param {*} param0 
 * @returns 
 */
const CommonImage = ({radius,url}) => {
  const observeImage = useRef(null);
  const [imageUrl,setImageUrl] = useState("");
  const showImage = async([entry],observer)=>{
    if (!entry.isIntersecting) {
      return
    }
    setImageUrl([entry][0].target.dataset.src);
    observer.unobserve(entry.target)
  }
  useEffect(()=>{
    const observer = new IntersectionObserver(showImage,{threshold:0.1})
    observer.observe(observeImage.current)
    return () => {
      observer.disconnect()
    }
  },[])
  return (
    <ImageWrap ref={observeImage} data-src={url}>
      {
        imageUrl === "" ? 
        <SkeletonImage radius={radius}/> :
        <Image 
        src={imageUrl}
        ref={observeImage}
        radius={radius}
        />
      }
    </ImageWrap>
  )
}

export default CommonImage;