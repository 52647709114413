import { Con, Head, List, ListA, ListItem, ListUl, Review } from "./overview_style";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from "styled-components";

const SkeletonA = styled(ListA)`
  background: #f8f5f5;
`

const SkeletonHead = styled(Skeleton)`
  width: 100px;
  height: 20px;
`

const SkeletonCont = styled(Skeleton)`
  width: 300px;
  height: 20px;
`

const SkeletonListImg = styled(Skeleton)`
  flex-shrink: 0;
  width: 57px;
  height: 57px;
  background: #ebebeb;
`

const SkeletonTitle = styled(Skeleton)`
  display: block;
  margin: 0 0 5px 0;
  line-height: 18px;
  font-size: 13px;
  font-weight: bold;
  color: #1f1f1f;
  background: #ebebeb;
  width: 100%;
  height: 18px;
`

const SkeletonBody = styled(Skeleton)`
  line-height: 18px;
  font-size: 13px;
  color: #1f1f1f;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 32px;
  width: 100%;
  background: #ebebeb;
`



/**
 * 상품 상단 리뷰 스켈레톤
 * @returns 
 */
const OverviewSkeleton = () => {
  return (
    <Review>
      <Head>
        <SkeletonHead/>
      </Head>
      <SkeletonCont/>
      <List>
        <ListUl>
          <ListItem>
            <SkeletonA>
              <SkeletonListImg/>
              <Con>
                <SkeletonTitle></SkeletonTitle>
                <SkeletonBody height={20}></SkeletonBody>
              </Con>
            </SkeletonA>
          </ListItem>
          <ListItem>
            <SkeletonA>
              <SkeletonListImg/>
              <Con>
                <SkeletonTitle></SkeletonTitle>
                <SkeletonBody height={20}></SkeletonBody>
              </Con>
            </SkeletonA>
          </ListItem>
        </ListUl>
      </List>
    </Review>
  );
}

export default OverviewSkeleton;