
import styled from "styled-components";


const Delivery = styled.div`
  padding: 20px;
  border-bottom: 1px solid #efefef;
  background: #ffffff;
`

const DeliveryDl = styled.dl`
  display: flex;
  line-height: 20px;
  font-size: 14px;
`

const DeliveryDt = styled.dt`
  flex-shrink: 0;
  width: 80px;
  font-weight: bold;
  color: #707070;
`

const DeliveryDd = styled.dd`
  flex: 1;
  color: #303030;
`

const DeliveryInfo = styled.p`
  margin: 4px 0 0 0;
  font-size: 13px;
  color: #9F9F9F;
`

/**
 * 배송 정보 컴포넌트
 * @returns 
 */
const ProductDelivery = () => {
  return (

    <Delivery>
      <DeliveryDl>
        <DeliveryDt>배송정보</DeliveryDt>
        <DeliveryDd>
          <span> 배송비 3,000원 </span>
          <DeliveryInfo>(50,000원 이상 구매 시 무료)</DeliveryInfo>
        </DeliveryDd>
      </DeliveryDl>
    </Delivery>
  )

}

export default ProductDelivery;