import axios from "axios";
import { $Axios } from "./custeomAxios";
/**
 * 상품 상세 정보 조회
 * @returns 
 */
export async function getProduct(idx,userIdx){
  try{
    const params = {
      idx : idx,
      userIdx : userIdx
    }
    const {data} = await $Axios.get("/product/detail",{params})
    return data;
  }catch(err){
    console.log(err);
  }
}


/**
 * 상품 페이지 상단 리뷰 조회
 * @returns 
 */
export async function getProductOverViewReview(idx){
  try{
    const params = {
      idx : idx
    }
    const {data} = await $Axios.get("/review/overview",{params})
    const {result} = data;
    return result;
  }catch(err){
    console.log(err);
  }
}

/**
 * 관련 상품 조회
 * @returns 
 */
export async function getRelactionProduct(idx,userIdx){
  try{
    const params = {
      idx : idx,
      userIdx : userIdx
    }
    const {data} = await $Axios.get("/product/related",{params})
    const {list} = data;
    return list;
  }catch(err){
    console.log(err);
  }
}


/**
 * 상품 qna 조회
 * @param {*} idx 
 * @returns 
 */
export async function getProductQna(idx){
  try{
    const params = {
      idx : idx
    }
    const {data} = await $Axios.get("/qna/product",{params})
    const {list} = data.result;
    return list;
  }catch(err){
    console.log(err);
  }
}


/**
 * 상품 아이템 조회
 * @param {*} idx 
 * @returns 
 */
export async function getProductItems(idx){
  try{
    const params = {
      idx : idx
    }
    const {data} = await $Axios.get("/product/item",{params})
    const {list} = data;
    return list;
  }catch(err){
    console.log(err);
  }
}


/**
 * 장바구니 등록
 * @param {*} list 
 * @returns 
 */
export async function setCartItem(list,userIdx){
  try{
    const params = {
      list : list,
      userIdx : userIdx
    }
    const {data} =await $Axios.post("/cart/item",params);
    return data;

  }catch(err){
    console.log(err);
  }
}

/**
 * 위시 리스트 토글
 * @param {*} userId 
 * @param {*} productId 
 * @param {*} status 
 * @returns 
 */
export async function setToggleFavorite(userIdx,productIdx,status){
  try{
    const params = {
      userId : userIdx,
      productId : productIdx,
      status
    }
    const {data} = await $Axios.post("/product/favorite",params);
    return data;
  }catch(err){
    console.log(err);
  }
}



/**
 * 상품 리뷰 조회
 */
export async function getProductReview(idx,page,search){
  try{
    console.log(search);
    const params = {
      idx,
      page,
      ...search
    }
    const {result} = await (await $Axios.get("/review/product",{params})).data;
    return result;
  }catch(err){
    console.log(err);
  }
}

/**
 * 상품 리뷰 코멘트 조회
 * @param {*} uuid 
 * @returns 
 */
export async function getReviewComment(uuid){
  try{
    const params = {
      uuid
    }
    const {result}= await (await $Axios.get("/review/comment",{params})).data;
    return result
  }catch(err){
    console.log(err);
  }
}

/**
 * 상품 리뷰 코멘트 등록
 * @param {*} uuid 
 * @param {*} userId 
 * @param {*} content 
 * @returns 
 */
export async function setReviewComment(uuid,userId,content){
  try{  
    const parmas = {
      uuid,
      userId,
      content
    }
    const {success,cnt} = await (await $Axios.post("/review/comment",parmas)).data;
    const res = {
      success,
      cnt
    }
    return res;
  }catch(err){
    console.log(err);
  }
}

/**
 * 리뷰 좋아요
 * @param {*} id 
 * @param {*} status 
 * @returns 
 */
export async function setLikeCount(id,status){
  try{
    const parmas = {
      id,
      status
    }
    const {success} = await (await $Axios.post("/review/like",parmas)).data;
    return success;
  }catch(err){
    console.log(err);
  }
}

/**
 * 장바구니 상품 수량 조회
 * @param {*} userIdx 
 * @returns 
 */
export async function getCartCount(userIdx){
  try{
    const params = {
      userIdx
    }
    const result = await (await $Axios.get("/cart/count",{params})).data;
    return result.cnt;
  }catch(err){
    console.log(err);
  }
}


/**
 * 유저별 상품 조회수 카운팅
 * @param {*} productId 
 * @param {*} userId 
 * @returns 
 */
export async function countProductView(productIdx,userIdx){
  try{
    const params = {
      userId : userIdx,
      productId : productIdx
    }
    const result = await (await $Axios.get("/product/view",{params})).data;
    return result;
  }catch(err){
    console.log(err);
  }
}




export async function getCoupos(productIdx){
  try{
    const params = {
      productId : productIdx
    }
    const result = await (await $Axios.get("/product/coupons",{params})).data
    return result.coupons;
  }catch(err){
    console.log(err);
  }
}

export async function getContext(){
  try{
    let form = new FormData();
    form.append("items[]",['customer'])
    const result = await (await axios.post("https://slim9.biz/context",form)).data;
    return result.data;
  }catch(err){
    console.log(err);
  }
}

export async function applyUserCoupon(couponIdx,userIdx){
  try{
    // let form = new FormData();
    // form.append("couponId",couponId)
    // form.append("customerId",userId)
    let params = {
      couponId : couponIdx,
      customerId : userIdx
    }
    const result = await axios.post("http://slim9.biz:3303/api/coupon/issue",params);
    return result;
  }catch(err){
    console.log(err);
  }

}

export async function applyProductCoupon(url){
  try{
    const result = await axios.post(url);
    console.log(result);
  }catch(err){
    console.log(err);
  }
}