const SEARCH = "REVIEWSEARCH";
const TOGGLE = "TOGGLEREVIEW";

export const ReviewSearch = (search) => {
  return{
    type : SEARCH,
    payload : {
      search
    }
  }
}

export const ToggleReview = () => {
  return {
    type : TOGGLE
  }
}

const initialState = {
  search : {
    girth: "",
    height: "",
    orders: "popular",
    types: "text",
    weight: ""
  },
  toggle : false
}

export default function reviewState(state = initialState,action){
  switch(action.type){
    case SEARCH:
      return{
        search : action.payload.search,
        toggle : state.toggle
      }
    case TOGGLE:
      return{
        search : state.search,
        toggle : !state.toggle
      }
    default:
      return state;
  }
}