import styled from "styled-components"
import { changePriceFormat } from "../../../util"
import CommonImage from "../common_image"
import ItemFavorite from "./item_favorite"

const ProductItemWrap = styled.a`
  position: relative;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0,0,0);
  width: 150px;
  margin-right: 10px;
`

const ProductItemImg = styled.div`
  position: relative;
  display: block;
  width: 150px;
  height: 200px;
`

const ProductItemSpan = styled.span`
  width: 100%;
  height: 0;
  padding-top: 133.55%;
  position: relative;
  display: block;
`
const ProductImageWrap = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
`

const ProductCon = styled.div`
  position: relative;
  margin: 12px 0 0 0;
  padding: 0 0px;
  display: block;
  .name{
    margin: 0 0 12px 0;
    min-height: 34px;
    line-height: 17px;
    font-size: 12px;
    font-weight: 900;
    color: #000000;
  }
  .price{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 20px;
  }
  .current{
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
  .discount{
    font-size: 13px;
    color: #CFCFCF;
    text-decoration: line-through;
    margin-left: 18px;
  }
`

const BtnZziz = styled.button`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 0px;
`






/**
 * (공통) 상품 아이템
 * @param {*} param0 
 * @returns 
 */
const ProductItem = ({item}) =>{


  const productLink = () => {
    window.location.href = "https://slim9.biz/products/"+item.id;
  }

  return (
    <ProductItemWrap onClick={productLink}>
      <ProductItemImg>
        <ProductItemSpan>
          <ProductImageWrap>
            <CommonImage url={item.images[0]} radius={'10px'}/>
          </ProductImageWrap>
        </ProductItemSpan>
        <BtnZziz>
          <ItemFavorite useFavorite={item.userFavorite} productIdx={item.id}/>
        </BtnZziz>
      </ProductItemImg>
      <ProductCon>
        <div className="name">{item.name}</div>
          <div className="money">
            <div className="price">
                <p className="current">{changePriceFormat(item.price)}원</p>
                <p className="discount">{changePriceFormat(item.regular_price)}원</p>
            </div>
        </div>
      </ProductCon>
    </ProductItemWrap>
  )
}

export default ProductItem;