
import styled from "styled-components";

const Icon = styled.i`
  display: block;
  width: 100%;
  height: 100%;
  background: url(${props => props.background}) no-repeat 50% 50% / 100% auto;;
`

/**
 * 공통 아이콘 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const Icons = ({url}) => {
  return (
    <Icon background={url}/>
  )
}

export default Icons;