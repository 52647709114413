import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"
import { getReviewComment, setLikeCount, setReviewComment } from "../../../server/api"
import {useDispatch,useSelector} from "react-redux"
import { ToggleReview } from "../../../store/modules/review"
const Help = styled.div`
  padding: 13px;
  border-top: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    font-size: 12px;
    font-weight: 500;
    color: #1f1f1f;
    line-height: 16px;
  }
  span{
    display: flex;
  }

`

const HelpCtrl = styled.a`
  font-size: 12px;
  font-weight: 500;
  color: #1f1f1f;
  text-decoration: underline;
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.btnType === 'no' ? "18px" : "0px"};
`
const CommentBtn = styled(HelpCtrl)`
  font-size: 12px;
  font-weight: 500;
  color: #1f1f1f;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 18px;
  img{
    margin: -3px 5px 0 0;
    height: 14px;
    width: auto;
  }
`

const Reply = styled.div`
  border-top: 1px solid #efefef;
  padding: 18px 15px;
  .name{
    margin: 0 0 14px 0;
    line-height: 17px;
    font-size: 13px;
    font-weight: bold;
    color: #303030;
  }
  .text{
    line-height: 19px;
    font-size: 13px;
    color: #303030;
  }
  .send{
    display: flex;
    margin: 0px 0 0 0;
    input[type="text"]{
      flex: 1;
      padding: 0 12px;
      border: 1px solid #D3D3D3;
    }
    button{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      height: 35px;
      font-size: 13px;
      background: #fff;
      text-align: center;
      white-space: nowrap;
      flex-shrink: 0;
      min-width: auto;
      margin-left: 10px;
      color: #ffffff;
      border: 1px solid #CD3D65;
      background-color: #CD3D65;
    }
  }
`


/**
 * 상품 리뷰 아이템 하단 헬프 컴포넌트
 * @param {*} param0 
 * @returns 
 */
const ProductReviewHelp = ({review}) => {
  const userIdx = useSelector((state) => state.userState.idx)
  const [comment,setComment] = useState("");
  const [commentCnt,setCommentCnt] = useState(0);
  const [isComment,setIsComment] = useState(false);
  const dispatch = useDispatch();
  
  const {data,isLoading,refetch} = useQuery(
    ["ReviewComment"+review.uuid],
    () => getReviewComment(review.uuid),
    {
      enabled:false
    })

  const showComment = () => {
    if(!isComment){
      refetch();
    }
    
    setIsComment(!isComment);
  }

  const changeComment = (e) => {
    setComment(e.target.value);
  }
  const sendComment = async () => {
    if(comment == ""){
      alert("댓글 내용을 입력해주세요.");
      return;
    }
    const {success,cnt} = await setReviewComment(review.uuid,userIdx,comment);
    if(success){
      refetch();
      setComment("");
      setCommentCnt(cnt);
    }
  }
  
  
  const setLike = async(status)=>{
    const success = await setLikeCount(review.id,status)
    if(success){
      dispatch(ToggleReview())
    }
  }


  useEffect(()=>{
    setCommentCnt(review.comments_count)
  },[review])

  return (
    <>
      <Help>
        <p>이 리뷰가 도움이 되었나요?</p>
        <span>
          <HelpCtrl btnType={'yes'} onClick={() => setLike("like")}>네</HelpCtrl>
          <HelpCtrl btnType={'no'} onClick={() => setLike("no")}>아니오</HelpCtrl>
          <CommentBtn onClick={showComment}>
            <img src="https://slim9.biz/assets/images/icon_reply.png" loading="lazy"/>
            <span>{commentCnt}</span>
          </CommentBtn>
        </span>
      </Help>
      {
        isComment && !isLoading ? 
          <>
            {
              data.list.map((item,index)=>(
              <Reply key={index}>
                <div className="name">{item.name}</div>
                <div className="text" dangerouslySetInnerHTML={ {__html: item.contents} }></div>
              </Reply>
              ))
            }
            <Reply>
              <div className="send">
                <input value={comment} onChange={changeComment} type="text" placeholder="댓글을 입력해 주세요."/>
                <button onClick={sendComment}>등록</button>
              </div>
            </Reply>
          </>
        : null
      }

    </>

  )
}

export default ProductReviewHelp